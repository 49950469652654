import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import Register from "./Register";
import ForgotPassword from "./ForgotPassword";
import { create } from "../services/upload-files.service";
import { auth, googleProvider, signInWithPopup } from "../firebase/Firebase";
import Loader from "./Loader";

export default function Login() {
  const [formData, setFormData] = useState({});
  const [validationErrors, setValidationErrors] = useState({});
  const navigate = useNavigate();
  const [isRightPanelActive, setRightPanelActive] = useState(false);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [redirecting, setRedirecting] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => setPasswordVisible(!passwordVisible);

  const handleClose = () => setShow(false);
  const handleShowForgotPass = () => {
    setShow(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setValidationErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
  };

  const handleSignUpClick = () => {
    setRightPanelActive(true);
  };

  const handleSignInClick = () => {
    setRightPanelActive(false);
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateFormFields(formData);
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
    } else {
      try {
        const res = await create(formData, 'login');
        if (res && Object.keys(res).length > 0) {
          setValidationErrors({ success: 'Successfully logged in!' })
          // localStorage.setItem('userdetails', JSON.stringify(res.user));
          localStorage.setItem('email', res.user.email);
          localStorage.setItem('amoeba_token', res.token);
          // localStorage.setItem('permissions', JSON.stringify(res.permissions));

          // refreshPermission(JSON.stringify(res.permissions));
          setTimeout(() => {
            const route = '/homepage'
            navigate(route, { state: { user: res } });
          }, 200)

        } else {
          setValidationErrors({ err: 'Please enter a valid email and password!' });
        }
      } catch (error) {
        setValidationErrors({ err: 'An error occurred. Please try again later.' });
      }
      setFormData({});
      // setValidationErrors({});
    }
  };

  const handleSocialLogin = async (provider) => {
    try {
      setLoading(true);
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      setRedirecting(true);

      const userData = {
        email: user.email,
        password: user.displayName,
      };

      const res = await create(userData, "login");

      if (res && res.token) {
        localStorage.setItem('amoeba_token', res.token);
        localStorage.setItem('email', res.user.email);
        localStorage.setItem('userdetails', JSON.stringify(res.user));

        setValidationErrors({ success: "Successfully logged in via social login" });
        setTimeout(() => {
          navigate("/homepage", { state: { user: res.user } });
        }, 1000);
      } else {
        setValidationErrors({ err: "Login failed via social login" });
        setTimeout(() => setValidationErrors({}), 3000);
      }
    } catch (error) {
      setValidationErrors({ err: "An error occurred during social login" });
      setTimeout(() => setValidationErrors({}), 3000);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (redirecting) {
      setLoading(true);
    }
  }, [redirecting]);

  const validateFormFields = (formData) => {
    const errors = {};

    if (!formData.email || formData.email.trim() === '') {
      errors.email = 'Please enter an email address';
    }

    if (!formData.password || formData.password.trim() === '') {
      errors.password = 'Please enter a password';
    }

    return errors;
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  return (
    <>
      {(loading || redirecting) && <Loader />}
      <Container className={`${isRightPanelActive ? "right-panel-active" : ""}`}>
        <Row className="justify-content-center">
          <Col>
            <Register />
            <div className="form-container sign-in-container">
              <form onSubmit={handleSubmit}>
                <h1>Sign in</h1>
                <div className="social-container">
                  {/* <a href="#" className="social" onClick={() => handleSocialLogin(facebookProvider)}>
                    <i className="fab fa-facebook-f"></i>
                  </a> */}
                  <a href="#" className="social" onClick={() => handleSocialLogin(googleProvider)}>
                    <i className="fab fa-google-plus-g"></i>
                  </a>
                  {/* <a href="#" className="social">
                    <i className="fab fa-linkedin-in"></i>
                  </a> */}
                </div>
                <span>or use your account</span>
                <input
                  type="email"
                  placeholder="Email"
                  name="email"
                  value={formData.email || ''}
                  onChange={handleInputChange}
                />
                {validationErrors.email && <div className="error login-error">{validationErrors.email}</div>}
                <div className="password-container">
                  <input
                     type={passwordVisible ? 'text' : 'password'}
                    placeholder="Password"
                    name="password"
                    value={formData.password || ''}
                    onChange={handleInputChange}
                  />
                  <FontAwesomeIcon
                    icon={passwordVisible ? faEye : faEyeSlash}
                    onClick={togglePasswordVisibility}
                    className="eye-icon"
                  />
                </div>
                {validationErrors.password && <div className="error login-error">{validationErrors.password}</div>}
                {/* <button
                  className="forgotpassword"
                  onClick={handleShowForgotPass}
                >
                  Forgot your password?
                </button> */}
                <Link
                  className="forgotpassword"
                  to="/forgotpassword"
                >Forgot your password?</Link>
                <button type="submit">Sign In</button>
              </form>
            </div>
            <div className="overlay-container">
              <div className="overlay">
                <div className="overlay-panel overlay-left">
                  <h1>Welcome Back!</h1>
                  <p>To keep connected with us please login with your info</p>
                  <button
                    onClick={handleSignInClick}
                    className="ghost"
                    id="signIn"
                  >
                    Login
                  </button>
                </div>
                <div className="overlay-panel overlay-right">
                  <h1>Hello, Friend! Join Us Now</h1>
                  <p>Enter your details and start journey with us</p>
                  <button
                    onClick={handleSignUpClick}
                    className="ghost"
                    id="signUp"
                  >
                    Register
                  </button>
                </div>
              </div>
            </div>
            <ForgotPassword show={show} handleClose={handleClose} />
          </Col>
        </Row>
      </Container>
    </>
  );
}
