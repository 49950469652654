import React, { useState, useEffect, useRef } from "react";
import { OrgChart } from "d3-org-chart";
import useFetch from "../hooks/useFetch";

const OrgChartComponent = () => {
    const chartRef = useRef();
    const { data, loading } = useFetch("get-connection-by-id");
    const [chart, setChart] = useState(null);

    const flattenData = (node, parentId = null, result = []) => {
        if (!node) return result;
        result.push({ id: node.id, name: node.name, help_area: node.help_area, parentId });

        if (node.children && node.children.length > 0) {
            node.children.forEach((child) => flattenData(child, node.id, result));
        }
        return result;
    };

    useEffect(() => {
        if (data && data.id && chartRef.current) {
            console.log("Fetched Data:", data);

            const formattedData = flattenData(data);
            console.log("Formatted Data:", formattedData);

            const newChart = new OrgChart()
                .container(chartRef.current)
                .data(formattedData) 
                .nodeHeight(() => 75)
                .nodeWidth(() => 200)
                .nodeContent((d) => `
                    <div class="chart-box-section" style="padding:16px; display: flex; align-items: center; flex-direction: column; border-radius:10px;">
                      <div class="user-name"><span>${d.data.name}</span></div>
                      <div class="user-title"><span>${d.data.help_area || "No Data"}</span></div>
                    </div>
                `)
                .childrenMargin(() => 60) 
                .compact(false)
                .render();

            setChart(newChart);
        }
    }, [data]);

    if (loading) {
        return <p>Loading...</p>;
    }

    return <div ref={chartRef} style={{ height: "100vh", width: "100%", overflow: "auto" }} />;
};

export default OrgChartComponent;
