import React, { useState, useRef, useEffect } from "react";
import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { create } from "../services/upload-files.service";
import { Link } from "react-router-dom";
import useFetch from "../hooks/useFetch";
import OrgChartComponent from "./OrgChartComponent";

export default function Homepage() {
  const [tags, setTags] = useState([]);
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    date: new Date().toISOString().slice(0, 10),
    type: '',
  });
  const [inputValue, setInputValue] = useState("");
  const [emailError, setEmailError] = useState("");
  const tagInputRef = useRef(null);
  const tagAreaRef = useRef(null);
  const labelRef = useRef(null);
  const [validationErrors, setValidationErrors] = useState({});
  const [show, setShow] = useState(false);
  const { data, loading } = useFetch('get-connection-by-id')
  const [treeData, setTreeData] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const [visibleStartIndex, setVisibleStartIndex] = useState({});

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleLogout = () => {
    localStorage.removeItem('amoeba_token');
    localStorage.removeItem('email');
  };

  const handleTagAreaClick = () => {
    tagInputRef.current.focus();
  };

  const handleFocus = () => {
    tagAreaRef.current.classList.add("active");
    labelRef.current.classList.add("label-active");
  };

  const handleBlur = () => {
    tagAreaRef.current.classList.remove("active");
    if (inputValue === "" && tags.length === 0) {
      labelRef.current.classList.remove("label-active");
    }
    if (!inputValue.match(/^\s+$/gi) && inputValue !== "") {
      setTags((prevTags) => [...prevTags, inputValue.trim()]);
      setInputValue("");
    }
  };

  const handleKeyDown = (e) => {
    const value = e.target.value;
    if (
      (e.keyCode === 32 || e.keyCode === 13 || value[value.length - 1] === " ") &&
      !value.match(/^\s+$/gi) &&
      value !== ""
    ) {
      setTags((prevTags) => [...prevTags, value.trim()]);
      setInputValue("");
    }
    if (e.keyCode === 8 && value === "") {
      setTags((prevTags) => prevTags.slice(0, -1));
    }
  };

  const handleRemoveTag = (indexToRemove) => {
    setTags((prevTags) =>
      prevTags.filter((_, index) => index !== indexToRemove)
    );
  };

  useEffect(() => {
    const tagAreaElement = tagAreaRef.current;
    tagAreaElement.addEventListener("click", handleTagAreaClick);

    return () => {
      tagAreaElement.removeEventListener("click", handleTagAreaClick);
    };
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  // Email validation function
  const validateEmails = (emails) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Regex for basic email validation
    for (let email of emails) {
      if (!emailRegex.test(email)) {
        return false; // Return false if any email is invalid
      }
    }
    return true;
  };

  const handleSendInvite = async (e) => {
    e.preventDefault();

    // Validate the email tags
    if (tags.length === 0) {
      showError("Please add at least one email.");
      return;
    }

    if (!validateEmails(tags)) {
      showError("One or more emails are invalid. Please enter valid emails.");
      return;
    }

    // Clear any previous errors
    clearError();

    const emails = {
      "invited_email": tags
    };

    try {
      const res = await create(emails, "users/add-user");

      if (res && res.msg === 'user already exists') {
        showError("This email already exists. Please enter a different one.");
        return;
      }

      if (res && res.affectedRows > 0) {
        showSuccess("Successfully Sent Invitation");
        clearFields();
      } else {
        showError("Please enter valid information");
      }
    } catch (error) {
      console.error("Error occurred:", error);
      showError("An error occurred. Please try again later.");
    }
  };

  // Helper functions
  const showError = (message) => {
    setEmailError(message);
    setValidationErrors({});

    setTimeout(() => {
      clearError();
    }, 3000);
  };

  const showSuccess = (message) => {
    setValidationErrors({ success: message });

    setTimeout(() => {
      setValidationErrors({});
    }, 3000);
  };

  const clearError = () => {
    setEmailError("");
    setValidationErrors({});
  };

  const clearFields = () => {
    setTags([]);
    setInputValue("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateFormFields(formData);
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
    } else {
      try {
        const res = await create(formData, "create-help");
        if (res.length > 0) {
          setValidationErrors({ success: "Successfully Help Area Created" });
          setShow(false);
        } else {
          setValidationErrors({ err: "Please enter valid information" });
        }
      } catch (error) {
        setValidationErrors({
          err: "An error occurred. Please try again later.",
        });
      }
      setFormData({});
    }
  };

  const validateFormFields = (formData) => {
    const errors = {};
    if (!formData.title || formData.title.trim() === "") {
      errors.title = "Please enter title";
    }

    if (!formData.description || formData.description.trim() === "") {
      errors.description = "Please enter description";
    }

    if (!formData.date || formData.date.trim() === "") {
      errors.date = "Please enter a date";
    }

    if (!formData.type || formData.type.trim() === "") {
      errors.type = "Please select type";
    }

    return errors;
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <Container>
        <Row>
          <Col>
            <div className="homepage">
              <h1>Amoeba</h1>
              <h3>Add Mentor / Mentees</h3>
              <button><Link to='/' onClick={handleLogout} className="text-white">Sign out</Link></button>
              <button onClick={handleShow} className="me-2">Create Help</button>
              <div className="tag-area" ref={tagAreaRef}>
                <label className={`label ${inputValue || tags.length ? "label-active" : ""}`} htmlFor="tag-input" ref={labelRef}>
                  Add Emails
                </label>
                <ul className="tag-list">
                  {tags.map((tag, index) => (
                    <li key={index} className="tag">
                      {tag}
                      <span className="cross" onClick={() => handleRemoveTag(index)}>&times;</span>
                    </li>
                  ))}
                  <li className="input-container">
                    <input
                      type="text"
                      className="tag-input"
                      id="tag-input"
                      ref={tagInputRef}
                      value={inputValue}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      onChange={(e) => setInputValue(e.target.value)}
                      onKeyDown={handleKeyDown}
                    />
                  </li>
                </ul>
                <button onClick={handleSendInvite} className="sendinvite">Send Invite</button>
              </div>

              {emailError && <p className="tag-error">{emailError}</p>}
              {validationErrors.err && <p className="tag-error">{validationErrors.err}</p>}
              {validationErrors.success && <p className="tag-success">{validationErrors.success}</p>}

              <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                  <Modal.Title>Create Help</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <form onSubmit={handleSubmit}>
                    <label>Title</label>
                    <input
                      type="text"
                      name="title"
                      value={formData.title || ''}
                      onChange={handleInputChange}
                    />

                    <label>Description</label>
                    <textarea
                      rows="3"
                      name="description"
                      value={formData.description || ''}
                      onChange={handleInputChange}
                    />

                    <label>Date</label>
                    <input
                      type="date"
                      name="date"
                      placeholder="DateRange"
                      value={formData.date || ''}
                      onChange={handleInputChange}
                    />

                    <label className="mt-2">Help Type</label>
                    <div className="radiogroup">
                      <label>
                        <input
                          name="type"
                          type="radio"
                          value="Mentor"
                          checked={formData.type === "Mentor"}
                          onChange={handleInputChange}
                        /> Mentor
                      </label>
                      <label>
                        <input
                          name="type"
                          type="radio"
                          value="Mentees"
                          checked={formData.type === "Mentees"}
                          onChange={handleInputChange}
                        /> Mentees
                      </label>
                    </div>
                    <button type="submit" className="modalbutton">Save Changes</button>
                  </form>
                </Modal.Body>
              </Modal>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Container>
              <h3 className="tree-heading">User List</h3>
              <OrgChartComponent />
            </Container>
          </Col>
        </Row>
      </Container>
    </>
  );
}
